export const stagger = {
  initial: {},
  animate: {
    transition: {
      
      staggerChildren: 0.1,
      delayChildren: 0.2,
    },
  },
}

export const ease = [0.46, 0.76, 0.7, 0.99]

export const fadeUp = {
  initial: {opacity: 0, y: 30},
  animate: {opacity: 1, y: 0, transition: {ease: ease, duration: .7,}}
}